import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import './Navbar.css';
import logo from '../../../assets/logo.png';

function MyNavbar() {
    const [activeSection, setActiveSection] = useState('');

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id);
                }
            });
        }, { threshold: 0.5 }); // Bölümün yarısının görünmesi durumunda kontrol eder

        document.querySelectorAll('header').forEach(header => observer.observe(header));

        // Navbar'daki her bir link için gözlem yap
        document.querySelectorAll('section[id]').forEach(section => {
            observer.observe(section);
        });

        return () => observer.disconnect(); // Bileşen kaldırıldığında gözlemi durdur
    }, []);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop - 69,
                behavior: 'smooth',

            });
        }
    }

    return (
        <Navbar expand="lg" fixed="top" className="bg-body-tertiary">
            <Container fluid>
                <Navbar.Brand href="#">
                    <img
                        src={logo ?? ""}
                        width="100"
                        className="d-inline-block align-top"
                        alt="KliGem"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav" className="justify-content-end">
                    <Nav className="ml-auto">
                        <Nav.Link
                            className={activeSection === 'about' ? 'activeSec' : ''}
                            onClick={(e) =>  scrollToSection('about')}
                        >Hakkımızda</Nav.Link>

                        <Nav.Link
                            className={activeSection === 'features' ? 'activeSec' : ''}
                            onClick={(e) => scrollToSection('features')}
                        >Özellikler</Nav.Link>

                        <Nav.Link
                            className={activeSection === 'pricing' ? 'activeSec' : ''}
                            onClick={(e) => scrollToSection('pricing')}
                        >Fiyat</Nav.Link>

                        <Nav.Link
                            className={activeSection === 'contact' ? 'activeSec' : ''}
                            onClick={(e) => scrollToSection('contact')}
                        >İletişim</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MyNavbar;
