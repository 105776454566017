import {Col, Container, Row} from "react-bootstrap";
import React from "react";
import './Footer.css';

export default function Footer() {
    return (
        <footer className="App-footer">
            <Container>
                <Row>
                    <Col>
                        <p>&copy; 2024 KliGem. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}