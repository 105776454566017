import React from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import './Contact.css'; // Özel CSS dosyası

function Contact() {
    const [form, setForm] = React.useState({});

    const handleChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    };

    return (
        <section className="contact-section" id="contact">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8}>
                        <h2>Bizimle İletişime Geçin</h2>
                        <p>Aklınıza takılan bir şey mi var? Bizimle her zaman iletişime geçmekten çekinmeyiniz.</p>
                        <div className="form-area">
                            <div>
                                <label className="text-black" htmlFor="name">Ad ve Soyad</label>
                                <input
                                    id="name"
                                    className="form-control"
                                    placeholder="Adınızı ve Soyadınızı Giriniz"
                                    name="name"
                                    value={form.name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div>
                                <label className="text-black" htmlFor="email">Eposta</label>
                                <input
                                    id="email"
                                    className="form-control"
                                    placeholder="Eposta Adresinizi Giriniz"
                                    name="email"
                                    value={form.email}
                                    onChange={handleChange}
                                />
                            </div>

                            <div>
                                <label className="text-black" htmlFor="message">Mesajınız</label>
                                <textarea
                                    id="message"
                                    className="form-control"
                                    placeholder="Mesajınızı Giriniz"
                                    name="message"
                                    value={form.message}
                                    onChange={handleChange}
                                />
                            </div>

                            <Button variant="primary" type="submit">
                                Gönder
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Contact;
