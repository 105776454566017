import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './About.css'; // Özel CSS dosyası

function About() {
    return (
        <section className="about-section" id="about">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8}>
                        <h2>Hakkımızda</h2>
                        <p>
                            KliGem olarak, müşterilerimize en iyi masaüstü uygulama deneyimini sunmayı hedefliyoruz. Ekibimiz, yılların tecrübesine sahip uzmanlar tarafından oluşturulan kaliteli yazılımlar geliştirmektedir. Müşteri memnuniyeti odaklı yaklaşımımızla, ihtiyaçlarınızı karşılamak için buradayız.
                        </p>
                        <p>
                            Şirketimiz, yenilikçi çözümler sunmak için sürekli olarak çalışmakta ve teknoloji alanındaki gelişmeleri takip etmektedir. Sizin için en uygun çözümleri sağlamak ve işinizin başarısına katkıda bulunmak için sabırsızlanıyoruz.
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default About;
