import {Button, Col, Container, Row} from "react-bootstrap";
import React from "react";
import './HomeView.css';
import MyNavbar from "./components/Navbar";
import Contact from "./components/ContactUs";
import Footer from "./components/Footer";
import About from "./components/About";
import Pricing from "./components/Pricing";
import Features from "./components/Features";


const background = require('../../assets/back1.jpg');
const appImage1 = require('../../assets/appImage1.png');
const windowsIcon = require('../../assets/windows-icon.png');

export default function HomeView() {
    return (
        <div>
            <div className="App">
                <MyNavbar/>

                <header className="App-header" style={{backgroundImage: `url(${background})`}}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <h1>KliGem'e Hoşgeldiniz!</h1>
                                <p>Klinik yönetiminiz için en kolay çözüm.</p>
                                <Button variant="primary" onClick={(e) => {
                                    e.preventDefault();
                                    //windows download link
                                    window.location.href = "https://github.com/YasinSefa15/Klinik-Relase/releases/download/v0.2.0/KliGem-Setup-0.2.0.exe";
                                }}>
                                    <img src={windowsIcon ?? ""} alt="windows"
                                         style={{width: "20px", marginRight: "10px"}}></img>
                                    Uygulamayı İndir
                                </Button>
                            </Col>
                        </Row>
                        <img
                            src={appImage1 ?? ""}
                            alt="KliGem"
                            style={{
                                //position: "absolute",
                                width: "80%",
                                marginTop: "37px",
                                left: "15%",
                            }}
                        />
                    </Container>
                </header>

                <About/>

                <Features/>

                <Pricing/>

                <Contact/>

                <Footer/>
            </div>
        </div>
    );
}