// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    background-color: #f8f9fa; /* Navbar arka plan rengi */
    padding: 10px 0; /* Navbar içerisindeki içeriklerin yukarıdan ve aşağıdan içe kaymasını sağlar */
}

.navbar-brand {
    font-weight: bold; /* Navbar marka metni kalınlığı */
}

.nav-link {
    color: #333; /* Link metni rengi */
    font-size: 18px; /* Link metni font boyutu */
    margin-right: 20px; /* Linkler arasındaki sağ boşluk */
    transition: color 0.3s; /* Renk değişimi animasyonu */
}

.nav-link:hover {
    color: #010203; /* Link metni rengi */
    text-decoration: none; /* Link alt çizgisini kaldırır */
}

.nav-link.activeSec {
    color: #010203; /* Aktif link rengi */

}

.nav-link.activeSec::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px; /* Alt çizginin kalınlığı */
    background-color: #010203; /* Alt çizginin rengi */
    margin-top: 3px; /* Alt çizginin metinden ne kadar aşağıda olacağı */
}

.nav-link:last-child {
    margin-right: 0; /* Son linkin sağ boşluğunu kaldırır */
}

`, "",{"version":3,"sources":["webpack://./src/views/Home/components/Navbar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB,EAAE,2BAA2B;IACtD,eAAe,EAAE,+EAA+E;AACpG;;AAEA;IACI,iBAAiB,EAAE,iCAAiC;AACxD;;AAEA;IACI,WAAW,EAAE,qBAAqB;IAClC,eAAe,EAAE,2BAA2B;IAC5C,kBAAkB,EAAE,kCAAkC;IACtD,sBAAsB,EAAE,6BAA6B;AACzD;;AAEA;IACI,cAAc,EAAE,qBAAqB;IACrC,qBAAqB,EAAE,gCAAgC;AAC3D;;AAEA;IACI,cAAc,EAAE,qBAAqB;;AAEzC;;AAEA;IACI,WAAW;IACX,cAAc;IACd,WAAW;IACX,WAAW,EAAE,2BAA2B;IACxC,yBAAyB,EAAE,uBAAuB;IAClD,eAAe,EAAE,mDAAmD;AACxE;;AAEA;IACI,eAAe,EAAE,sCAAsC;AAC3D","sourcesContent":[".navbar {\n    background-color: #f8f9fa; /* Navbar arka plan rengi */\n    padding: 10px 0; /* Navbar içerisindeki içeriklerin yukarıdan ve aşağıdan içe kaymasını sağlar */\n}\n\n.navbar-brand {\n    font-weight: bold; /* Navbar marka metni kalınlığı */\n}\n\n.nav-link {\n    color: #333; /* Link metni rengi */\n    font-size: 18px; /* Link metni font boyutu */\n    margin-right: 20px; /* Linkler arasındaki sağ boşluk */\n    transition: color 0.3s; /* Renk değişimi animasyonu */\n}\n\n.nav-link:hover {\n    color: #010203; /* Link metni rengi */\n    text-decoration: none; /* Link alt çizgisini kaldırır */\n}\n\n.nav-link.activeSec {\n    color: #010203; /* Aktif link rengi */\n\n}\n\n.nav-link.activeSec::after {\n    content: '';\n    display: block;\n    width: 100%;\n    height: 2px; /* Alt çizginin kalınlığı */\n    background-color: #010203; /* Alt çizginin rengi */\n    margin-top: 3px; /* Alt çizginin metinden ne kadar aşağıda olacağı */\n}\n\n.nav-link:last-child {\n    margin-right: 0; /* Son linkin sağ boşluğunu kaldırır */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
