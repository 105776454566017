// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-section {
    background-color: #181b32;
    color: #f8f7f4;
    padding: 80px 0;
}

.contact-section h2 {
    font-size: 36px;
    margin-bottom: 30px;
}

.contact-section p {
    font-size: 18px;
    margin-bottom: 30px;
}

.contact-section .form-area {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-section .form-area label {
    font-weight: 500;
}

.contact-section .form-area input,
.contact-section .form-area textarea {
    margin-bottom: 20px;
    border-radius: 5px;
}
.contact-section .form-area textarea {
    height: 111px;
}

.contact-section .form-area button {
    padding: 12px 32px;
    font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Home/components/Contact.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".contact-section {\n    background-color: #181b32;\n    color: #f8f7f4;\n    padding: 80px 0;\n}\n\n.contact-section h2 {\n    font-size: 36px;\n    margin-bottom: 30px;\n}\n\n.contact-section p {\n    font-size: 18px;\n    margin-bottom: 30px;\n}\n\n.contact-section .form-area {\n    background-color: #ffffff;\n    padding: 30px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.contact-section .form-area label {\n    font-weight: 500;\n}\n\n.contact-section .form-area input,\n.contact-section .form-area textarea {\n    margin-bottom: 20px;\n    border-radius: 5px;\n}\n.contact-section .form-area textarea {\n    height: 111px;\n}\n\n.contact-section .form-area button {\n    padding: 12px 32px;\n    font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
