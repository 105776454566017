// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-pricing {
    background-color: #f8f9fa;
    padding: 80px 0;
    min-height: 95vh;
}

.App-pricing h2 {
    font-size: 36px;
    color: #333;
    margin-bottom: 30px;
}

.pricing-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    margin-bottom: 20px;
}

.pricing-card:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.pricing-card-body {
    padding: 40px;
}

.pricing-card-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.pricing-card-text {
    font-size: 18px;
    color: #555;
    margin-bottom: 20px;
}

.pricing-card-btn {
    background-color: #3f51b5;
    border-color: #3f51b5;
    border-radius: 25px;
    padding: 12px 32px;
    font-size: 18px;
}

.pricing-card-btn:hover {
    background-color: #303f9f;
    border-color: #303f9f;
}
`, "",{"version":3,"sources":["webpack://./src/views/Home/components/Pricing.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,wCAAwC;IACxC,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":[".App-pricing {\n    background-color: #f8f9fa;\n    padding: 80px 0;\n    min-height: 95vh;\n}\n\n.App-pricing h2 {\n    font-size: 36px;\n    color: #333;\n    margin-bottom: 30px;\n}\n\n.pricing-card {\n    background-color: #fff;\n    border-radius: 12px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: box-shadow 0.3s ease;\n    margin-bottom: 20px;\n}\n\n.pricing-card:hover {\n    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);\n}\n\n.pricing-card-body {\n    padding: 40px;\n}\n\n.pricing-card-title {\n    font-size: 24px;\n    color: #333;\n    margin-bottom: 20px;\n}\n\n.pricing-card-text {\n    font-size: 18px;\n    color: #555;\n    margin-bottom: 20px;\n}\n\n.pricing-card-btn {\n    background-color: #3f51b5;\n    border-color: #3f51b5;\n    border-radius: 25px;\n    padding: 12px 32px;\n    font-size: 18px;\n}\n\n.pricing-card-btn:hover {\n    background-color: #303f9f;\n    border-color: #303f9f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
