// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-section {
    background-color: #f8f9fa;
    padding: 80px 0;
    min-height: 95svh;
}

.about-section h2 {
    font-size: 36px;
    margin-bottom: 30px;
}

.about-section p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Home/components/About.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".about-section {\n    background-color: #f8f9fa;\n    padding: 80px 0;\n    min-height: 95svh;\n}\n\n.about-section h2 {\n    font-size: 36px;\n    margin-bottom: 30px;\n}\n\n.about-section p {\n    font-size: 18px;\n    line-height: 1.6;\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
