import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomeView from "./views/Home/HomeView";
import NotFoundView from "./views/Special/NotFoundView";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomeView/>}/>
                <Route path="*" element={<NotFoundView/>}/>
            </Routes>
        </BrowserRouter>
    );
}
