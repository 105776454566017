import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import './Pricing.css'; // Özel CSS dosyası

function Pricing() {
    return (
        <section className="App-pricing" id="pricing">
            <Container>
                <Row>
                    <Col>
                        <h2>Fiyatlandırma</h2>
                        <Row>
                            <Col className="d-flex justify-content-center align-items-center">
                                <div className="card w-50">
                                    <div className="card-body">
                                        <h5 className="card-title">YILLIK PLAN</h5>
                                        <div className="d-flex justify-content-center">
                                            <hr className="w-75"></hr>
                                        </div>

                                        <p style={{
                                            fontSize: "2.5em",
                                            textAlign: "center",
                                            fontWeight: "100", // Kalınlık normal (ince)
                                            marginBottom: "20px"
                                        }}>245₺/Ay</p>

                                        <Button variant="primary"
                                                onClick={() => {
                                                    window.location.href = '/order';
                                                }}
                                        >Devam Et</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Pricing;
