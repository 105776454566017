import {Container, Row} from "react-bootstrap";
import React from "react";

export default function Features() {
    return (
        <section className="App-info" id="features">
            <Container>
                <h2>Özellikler</h2>
                <div className="container w-75">
                    <Row className="justify-content-between mb-3">
                        <div className="d-flex" style={{
                            maxWidth: "400px",
                        }}>
                            <i className="bi bi-check me-2"/>
                            <div className="d-flex align-items-center">
                                <div className="">
                                    <h3 className="mb-1" style={{
                                        fontSize: "1.5rem",
                                        textAlign: "justify"
                                    }}>
                                        Kullanıcı Dostu Arayüz
                                    </h3>
                                    <p
                                        style={{
                                            fontSize: "1rem",
                                            textAlign: "justify"
                                        }}>
                                        KliGem, kolay gezinme ve her düzey kullanıcı için sorunsuz etkileşim sağlayan
                                        sezgisel bir kullanıcı arayüzü sunar.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex" style={{
                            maxWidth: "400px",
                        }}>
                            <i className="bi bi-check me-2"/>
                            <div className="d-flex align-items-center " style={{}}>
                                <div className="">
                                    <h3 className="mb-1" style={{
                                        fontSize: "1.5rem",
                                        textAlign: "justify"
                                    }}>
                                        Envanter Yönetimi
                                    </h3>
                                    <p
                                        style={{
                                            fontSize: "1rem",
                                            textAlign: "justify"
                                        }}>
                                        KliGem, envanter yönetim sistemleriyle sorunsuz entegrasyon sağlayarak klinik
                                        envanterinin etkili izlenmesine ve yönetilmesine olanak tanır.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </Row>

                    <Row className="justify-content-between mb-3">
                        <div className="d-flex" style={{
                            maxWidth: "400px",
                        }}>
                            <i className="bi bi-check me-2"/>
                            <div className="d-flex align-items-center">
                                <div className="">
                                    <h3 className="mb-1" style={{
                                        fontSize: "1.5rem",
                                        textAlign: "justify"
                                    }}>
                                        Randevu Yönetimi
                                    </h3>
                                    <p
                                        style={{
                                            fontSize: "1rem",
                                            textAlign: "justify"
                                        }}>
                                        Randevuları kolayca planlayabilir, güncelleyebilir ve iptal edebilirsiniz.
                                        KliGem, randevu yönetiminde size yardımcı olur.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex" style={{
                            maxWidth: "400px",
                        }}>
                            <i className="bi bi-check me-2"/>
                            <div className="d-flex align-items-center " style={{}}>
                                <div className="">
                                    <h3 className="mb-1" style={{
                                        fontSize: "1.5rem",
                                        textAlign: "justify"
                                    }}>
                                        Randevu Düzenleme
                                    </h3>
                                    <p
                                        style={{
                                            fontSize: "1rem",
                                            textAlign: "justify"
                                        }}>
                                        Randevuları hızlıca düzenleyebilir ve randevu programınızı güncel
                                        tutabilirsiniz. KliGem, size randevu düzenleme kolaylığı sunar.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </Row>

                    <Row className="justify-content-between mb-3">
                        <div className="d-flex" style={{
                            maxWidth: "400px",
                        }}>
                            <i className="bi bi-check me-2"/>
                            <div className="d-flex align-items-center">
                                <div className="">
                                    <h3 className="mb-1" style={{
                                        fontSize: "1.5rem",
                                        textAlign: "justify"
                                    }}>
                                        Personel Yönetimi
                                    </h3>
                                    <p
                                        style={{
                                            fontSize: "1rem",
                                            textAlign: "justify"
                                        }}>
                                        Çalışan personelinizi etkin bir şekilde yönetebilir, performanslarını
                                        izleyebilirsiniz. KliGem, personel yönetiminde size destek olur.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex" style={{
                            maxWidth: "400px",
                        }}>
                            <i className="bi bi-check me-2"/>
                            <div className="d-flex align-items-center " style={{}}>
                                <div className="">
                                    <h3 className="mb-1" style={{
                                        fontSize: "1.5rem",
                                        textAlign: "justify"
                                    }}>
                                        Müşteri Yönetimi
                                    </h3>
                                    <p
                                        style={{
                                            fontSize: "1rem",
                                            textAlign: "justify"
                                        }}>
                                        Müşteri bilgilerini kolayca kaydedip güncelleyebilirsiniz. KliGem, müşteri
                                        yönetiminde size kolaylık sağlar.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </Row>

                    <Row className="justify-content-between mb-3">
                        <div className="d-flex" style={{
                            maxWidth: "400px",
                        }}>
                            <i className="bi bi-check me-2"/>
                            <div className="d-flex align-items-center">
                                <div className="">
                                    <h3 className="mb-1" style={{
                                        fontSize: "1.5rem",
                                        textAlign: "justify"
                                    }}>
                                        Hizmet Yönetimi
                                    </h3>
                                    <p
                                        style={{
                                            fontSize: "1rem",
                                            textAlign: "justify"
                                        }}>
                                        Sunulan hizmetleri kaydedebilir, fiyatlarını ve açıklamalarını yönetebilirsiniz.
                                        KliGem, hizmet yönetiminde size yardımcı olur.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex" style={{
                            maxWidth: "400px",
                        }}>
                            <i className="bi bi-check me-2"/>
                            <div className="d-flex align-items-center " style={{}}>
                                <div className="">
                                    <h3 className="mb-1" style={{
                                        fontSize: "1.5rem",
                                        textAlign: "justify"
                                    }}>
                                        Ranveu Ödeme Takibi
                                    </h3>
                                    <p
                                        style={{
                                            fontSize: "1rem",
                                            textAlign: "justify"
                                        }}>
                                        Müşterilerinizin randevu ödeme durumlarını takip edin, gecikmeleri izleyin.
                                        Finansal yönetimi kolaylaştırın.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Row>

                    <Row className="justify-content-between mb-3">
                        <div className="d-flex" style={{
                            maxWidth: "400px",
                        }}>
                            <i className="bi bi-check me-2"/>
                            <div className="d-flex align-items-center">
                                <div className="">
                                    <h3 className="mb-1" style={{
                                        fontSize: "1.5rem",
                                        textAlign: "justify"
                                    }}>
                                        Raporlama ve Analiz
                                    </h3>
                                    <p
                                        style={{
                                            fontSize: "1rem",
                                            textAlign: "justify"
                                        }}>
                                        Kapsamlı raporlama ve analiz araçları ile klinik performansınızı izleyin. Gelir,
                                        müşteri sayısı, yaklaşmakta olan randevularınız gibi verilere erişin.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex" style={{
                            maxWidth: "400px",
                        }}>
                            <i className="bi bi-check me-2"/>
                            <div className="d-flex align-items-center " style={{}}>
                                <div className="">
                                    <h3 className="mb-1" style={{
                                        fontSize: "1.5rem",
                                        textAlign: "justify"
                                    }}>
                                        Müşteri Notları
                                    </h3>
                                    <p
                                        style={{
                                            fontSize: "1rem",
                                            textAlign: "justify"
                                        }}>
                                        Müşteri notları özelliğiyle, kliniğinizdeki müşterilerle ilgili önemli bilgileri
                                        kaydedin. Özel notlar ekleyin, özel talepleri not alın ve önemli ayrıntıları
                                        kolayca erişilebilir bir şekilde saklayın.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </Container>
        </section>
    )
}